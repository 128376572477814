import { useState, useEffect } from "react";

const useAuthCheck = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<{ avatar?: string } | null>(null);

  useEffect(() => {
    // Aquí puedes obtener la lógica de autenticación real
    const token = localStorage.getItem("token");
    if (token) {
      setIsAuthenticated(true);
      setUser({
        avatar: "src\images\avatars\Image-1.png", //avatar del usuario
      });
    } else {
      setIsAuthenticated(false);
    }
  }, []);

  return { isAuthenticated, user };
};

export default useAuthCheck;
